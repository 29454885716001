/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTSVG} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'

type Props = {
    className: string, title: string, series: Array<number>, labels: Array<string>,name: string
}

const ChartsWidgetGeneralBar: React.FC<Props> = ({ className, title, series, labels, name}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

      const chart = new ApexCharts(chartRef.current, getChartOptions(height, series, labels, title, name))
    if (chart) {
      chart.render()
    }

    return chart
  }

  return (
    <div className={`card card-bordered ${className}`}>
      {/* end::Header */}

      {/* begin::Body */}
          <div className='card-body'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_1_chart' />
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export { ChartsWidgetGeneralBar }

function getChartOptions(height: number, series: Array<number>, labels: Array<string>, title: string, name: string): ApexOptions {
    //console.log(series)
    //console.log(labels)

  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const baseColor = getCSSVariableValue('--kt-primary')
  const secondaryColor = getCSSVariableValue('--kt-gray-300')

  return {
      series: [
          {
              name: name,
              data: series,
          },
      ],
      chart: {
          fontFamily: 'inherit',
          type: 'bar',
          height: 350,
          toolbar: {
              show: false,
          },
      },
      plotOptions: {},
      legend: {
          show: false,
      },
      dataLabels: {
          enabled: false,
      },
      fill: {
          type: 'solid',
          opacity: 1,
      },
      stroke: {
          curve: 'smooth',
          show: true,
          width: 3,
          colors: [baseColor],
      },
      xaxis: {
          categories: labels,
          axisBorder: {
              show: false,
          },
          axisTicks: {
              show: false,
          },
          labels: {
              style: {
                  colors: labelColor,
                  fontSize: '12px',
              },
          },
          crosshairs: {
              position: 'front',
              stroke: {
                  color: baseColor,
                  width: 1,
                  dashArray: 3,
              },
          },
          tooltip: {
              enabled: true,
              formatter: undefined,
              offsetY: 0,
              style: {
                  fontSize: '12px',
              },
          },
      },
      yaxis: {
          labels: {
              style: {
                  colors: labelColor,
                  fontSize: '12px',
              },
          },
      },
      states: {
          normal: {
              filter: {
                  type: 'none',
                  value: 0,
              },
          },
          hover: {
              filter: {
                  type: 'none',
                  value: 0,
              },
          },
          active: {
              allowMultipleDataPointsSelection: false,
              filter: {
                  type: 'none',
                  value: 0,
              },
          },
      },
      tooltip: {
          style: {
              fontSize: '12px',
          },
          y: {
              formatter: function (val) {
                  return '' + val + ''
              },
          },
      },
      colors: [baseColor],
      title: {
          text: title,
          align: 'left',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
              fontSize: '14px',
              fontWeight: 'bold',
              fontFamily: undefined,
              color: '#263238'
          },
      },
      grid: {
          borderColor: borderColor,
          strokeDashArray: 4,
          yaxis: {
              lines: {
                  show: true,
              },
          },
      },
      markers: {
          strokeColors: baseColor,
          strokeWidth: 3,
      },
  }
}
