import { EnableSidebar, PageTitle } from '../../../_metronic/layout/core'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { ChartsWidgetGeneral, ChartsWidgetGeneralBar } from '../../../_metronic/partials/widgets'
import { Vertical } from '../../modules/wizards/components/Vertical';
import { getApplicationRole as getApplicationRole } from '../applicationrole/list/core/_requests'
import "./Hide.css"

const DashboardPage = () => {
    const intl = useIntl()
    const [organizations, setorganizations] = React.useState([])
    const [organizations2, setorganizations2] = React.useState<Array<number>>([])
    const [organizations3, setorganizations3] = React.useState<Array<string>>([])
    const [surveytypes, setsurveytypes] = React.useState([])
    const [semesters, setsemesters] = React.useState([])
    const [applicationroles, setapplicationroles] = React.useState([])
    //document?.getElementById("kt_header")?.classList.add("hide");

    useEffect(() => {
        
        getApplicationRole("items_per_page=99999", "Id,Name,ApplicationUserRole*ApplicationRoleId", "ApplicationUserRole").then((res: any) => {
            setapplicationroles(res.data.map((a: any) => { return { value: a?.applicationUserRoleApplicationRoleId.length, label: a?.name } }) || [])
        })
    }, [])

    useEffect(() => {
        setorganizations2(organizations.map((a: any) => a.value))
        setorganizations3(organizations.map((a: any) => a.label))
    }, [organizations])

    return (

        <>
            <PageTitle breadcrumbs={[]}>Dashboard</PageTitle>

            <div className='row'>
                {surveytypes.length > 0 && (surveytypes.map((a: any) => a.value).length > 10 ?
                    <div className='mb-7 col-12'>
                    <ChartsWidgetGeneralBar className="1" title={intl.formatMessage({ id: 'SurveyType.STATS' })} series={surveytypes.map((a: any) => a.value)} labels={surveytypes.map((a: any) => a.label)} name='Survey.COUNT' />
                </div>
                    : <div className='mb-7 col-4'>
                    <ChartsWidgetGeneral className="1" title={intl.formatMessage({ id: 'SurveyType.STATS' })} series={surveytypes.map((a: any) => a.value)} labels={surveytypes.map((a: any) => a.label)} />
                </div>
                )}
                {applicationroles.length > 0 && (
                    applicationroles.map((a: any) => a.value).length > 10 ?
                        <div className='mb-7 col-12'>
                            <ChartsWidgetGeneralBar className="2" title={intl.formatMessage({ id: 'ApplicationRole.STATS' })} series={applicationroles.map((a: any) => a.value)} labels={applicationroles.map((a: any) => a.label)} name='ApplicationUserRole.COUNT' />
                        </div>
                        : <div className='mb-7 col-4'>
                            <ChartsWidgetGeneral className="2" title={intl.formatMessage({ id: 'ApplicationRole.STATS' })} series={applicationroles.map((a: any) => a.value)} labels={applicationroles.map((a: any) => a.label)} />
                        </div>
                )}
                {semesters.length > 0 && (semesters.map((a: any) => a.value).length > 10 ?
                    <div className='mb-7 col-12'>
                        <ChartsWidgetGeneralBar className="" title={intl.formatMessage({ id: 'Semester.STATS' })} series={semesters.map((a: any) => a.value)} labels={semesters.map((a: any) => a.label)} name='SemesterCourse.COUNT' />
                    </div>
                    : <div className='mb-7 col-4'>
                        <ChartsWidgetGeneral className="" title={intl.formatMessage({ id: 'Semester.STATS' })} series={semesters.map((a: any) => a.value)} labels={semesters.map((a: any) => a.label)} />
                    </div>
                )}
                {organizations2.length > 0 && organizations3.length > 0 && (organizations.map((a: any) => a.value).length > 10 ?
                    <div className='mb-7 col-12'>
                        <ChartsWidgetGeneralBar className="3" title={intl.formatMessage({ id: 'Organization.Student.STATS' })} series={organizations2} labels={organizations3} name='Student.COUNT' />
                    </div>
                    : organizations2 && organizations3 && <div className={`mb-7 col-4 ${organizations.map((a: any) => a.value).length}`}>
                    <ChartsWidgetGeneral className="3" title={intl.formatMessage({ id: 'Organization.Student.STATS' })} series={organizations2} labels={organizations3} />
                </div>)
                }
            </div>
        </>
    )
}

const DashboardWrapper = () => {
  return (
    <EnableSidebar>
      <PageTitle breadcrumbs={[]}>Dashboard</PageTitle>
          <div className='card-xl-stretch mb-5 mb-xl-10'>
        <Vertical />
      </div>
     
    </EnableSidebar>
  )
}

export {DashboardPage}
